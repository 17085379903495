.contact-card {
  /* background-image: linear-gradient(to bottom right, #e87360, #f87d4d); */
  position: absolute;
  z-index: 1000;
  transition: all 1.3s ease;
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
    'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
    'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
    monospace;
}

.blue {
  color: #29b6f6;
}
.green {
  color: #9ccc65;
}

.purple {
  color: #ba68c8;
}
.cyan {
  color: #4dd0e1;
}
.red {
  color: #ef5350;
}
.content {
  height: 50vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  perspective: 150rem;
  height: 20rem;
  width: 30rem;
  position: relative;
}
.card__side {
  height: 15rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  width: 30rem;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
}
.card__side--front {
  background-color: #1c1c1c;
}
.card__side--back {
  transform: rotateY(180deg);
  background-color: #1c1c1c;
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0deg);
}
.card__cont {
  height: 15rem;
  background-color: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  color: white;
}
.card__cta p {
  margin-left: 1rem;
}
.card__cta p > .space {
  margin-left: 2rem;
}

a:hover {
  color: white;
  text-decoration: underline;
}

.btn-cancel {
  display: block;
  margin-left: auto;
  margin-top: 1rem;
  font-weight: 700;
  background-color: white;
}

.btn-openModal {
  background: none;
  font-weight: 700;
  font-size: 1.2rem;
  border: none;
  margin: 1rem;
}

.btn-openModal:hover {
  color: #e87360;
}

.back-drop {
  background: rgba(63, 61, 61, 0.692);
  height: 100%;
  width: 100%;
  /* position: fixed; */
  transition: all 1.3s;
}
