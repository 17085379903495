.portfolio-item-individual {
  padding: 0 2em 2em;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.portfolio-item-individual p {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

a {
  color: #333;
  text-decoration: none;
  margin: auto;
}
iframe {
  height: 400px;
  width: 650px;
  /* height: 30vh;
  width: 50vw; */
  margin: 0 auto;
}

#link {
  color: #e87360;
  font-weight: 700;
  text-decoration: underline;
}

@media (width: 600px) {
  iframe {
    width: 20vw;
    height: 30vh;
  }
}
